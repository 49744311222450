#main-search{
  &.callout{
    border:0;
    box-shadow: 0 0 10px darken($primary-color,40%);
  }

  label{
    text-align: center;
    font-size:.85rem;
    @include breakpoint(medium) {
      font-size: 1.15rem;
    }
    color:$body-font-color;
    font-family:$header-font-family;
  }

  button{
    &[type="submit"]{
      width:100%;
      font-weight: bolder;
    }
  }

  #advanced{
    &.form-group{
      width:100%;
      margin-top:1rem;
    }
    legend{
      font-family:$body-font-family;
      text-align: center;
      font-size:.85rem;
      @include breakpoint(medium) {
        font-size:1rem;
      }
    }
    label{
      text-align: left;
      @include breakpoint(small only) {
        font-size:.85rem;
      }
    }
    input{
      &[name="perimeter"]{
        background:none;
        font-size:1.15rem;
        color:$body-font-color;
        font-family:$header-font-family;
      }
    }
    .input-group-label{
      background:none;
      border:none;
      text-align: left;
      color:$medium-gray;
    }
    button{
        &.spinner{
          position:relative;
          background:$white;
        }
    }

  }

  #searchFormSearchNearby{
    &.spinner{
        color:$white;
    }
  }


@keyframes spinner {
to {transform: rotate(360deg);}
}

.spinner:before {
content: '';
box-sizing: border-box;
position: absolute;
width: 20px;
height: 20px;
margin-top: -3px;
margin-left: -2px;
border-radius: 50%;
border-top: 2px solid $primary-color;
border-right: 2px solid transparent;
animation: spinner .6s linear infinite;
}
}


.js{
  #main-search{
    #advanced{
      &.form-group{
        display:none;
      }
    }
  }
}

#standalone-search-container{
  @include breakpoint(medium) {
    padding:0 rem-calc(15);
  }
}

.autocomplete-wrap{
  position:relative;
  .autocompleter{
    display:none;
    position:absolute;
    background:white;
    height:300px;
    overflow-y: scroll;
    width: 100%;
    margin-top: -1rem;
    background: #333;
    &.active{
      display:block;
    }
    li{
      border-bottom:1px dotted $white;
      &:last-child{
        border-bottom:0;
      }
    }
  }
}
