@import url('https://fonts.googleapis.com/css?family=Roboto+Slab');

// Settings
@import "settings";

// Foundation
@import "../../../node_modules/foundation-sites/scss/foundation";

// Include Everything (True) for the Flex Grid :);
@include foundation-everything(true);

.button,button{
  background:$ms-gradient;
  border:0;
  &.hollow{
    background:none!important;
  }
}

@import "ms.header";
@import "ms.main";
@import "ms.search";
@import "ms.profile";
@import "ms.searchResults";
@import "ms.tags";
@import "ms.dynamicContent";
@import "../../../node_modules/shariff/dist/shariff.min";
//test
