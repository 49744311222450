#dynamic-content{
  margin-bottom:rem-calc(100);

  h3{
    font-size:1.25rem;
  }
  .callout{
    border:0;
    a.button{
      position: absolute;
      bottom: 1rem;
      width: 91%;
    }
    hr{border-color:$white;}
    figure{
      position:relative;
      margin-bottom:1rem;
      img{
        &:hover{
          filter:grayscale(1);
        }
      }
      figcaption{
        @include vertical-center;
        h3{
          background:$ms-gradient;
          color:$white;
          padding:.2rem;
          display:inline;
          line-height: 1.7;
        }
      }
    }

  }

}
