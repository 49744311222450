
#logo{
  text-align: center;
  width:100%;
}

#header{
  background:$white;
  [data-sticky-container] .sticky.is-stuck{
  box-shadow: 0 0 6px rgba($dark-gray,.5);background:$white;

  }
  @include breakpoint(small only) {
    height:75px!important;
  }

//  margin-bottom:100px;

@include breakpoint(small only) {
  button{
    margin-top: 8px;

    margin-bottom: 0;
  }
  .top-bar{
    position: absolute;
    left: 0;
    width: 100%;
    ul{
      width:100%;
      li{
        width:100%;
        border-bottom:1px dashed $light-gray;
        &:last-child{
          border:0;
        }
        ul{
          width:90%;
        }
        a{
          font-size:1.25rem;
          display:block;
        }
      }
    }

  }
}

  .no-js {
    @include breakpoint(small only) {
      .top-bar {
        display: none;
      }
    }

    @include breakpoint(medium) {
      .title-bar {
        display: none;
      }
    }
  }
  #logo{
    margin:0!important;
    width:auto;
    padding:1rem;
    /*height:140px;*/
    // BETA ONLY
    position:relative;

    @include breakpoint(small only) {

    }
//
//     &::after{
//       content: 'BETA';
// position: absolute;
// top: 10px;
// background: #d12e6c;//$ms-gradient;
// border-radius: 10px;
// font-size: 80%;
// padding: .15rem .5rem;
// color: $white;
// right: -10px;
//
//     }
  }
  .top-bar{
    @include breakpoint(medium up) {
      @include flex-align(right);
    }
    a{
      font-size:80%;
    }
  }
}

#standalone-logo-container{
  margin-bottom:100px;
}


[data-sticky] {
  width:100%;
}

.sticky-container {
  z-index: 5;
}
