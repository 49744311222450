html,body{
  background: #f0f0f0;
}

#main-content{

  padding-top:2rem;
  padding-bottom:2rem;

  h1{
    text-align:center;
  }
}


body:not(.homepage){
  #main-content{
    min-height:70vh;
  }
}

#stickyFooter{
  position:fixed;
  bottom:0;
  width:100%;
  text-align: center;
  background:rgba($medium-gray,.5);
  padding:.5rem;
  a.button{
    margin-bottom:0;
  }
  @include breakpoint(medium up) {

    background:none;
    text-align: right;
  }
}

.js #stickyFooter{
  display:none;
  transition: all .4s ease;
}

.js #nearbyMapWrapper{
  display:none;

  transition:all 4s ease;
  &.active{
    display:flex;
  }
}

#dynamic-content{
  opacity:0;
  visibility: hidden;
  transition:  1s all ease-in;
  &.active{
    visibility: visible;
    opacity: 1;
  }
}



#footer{
  background:$white;//$ms-gradient;
  color:$dark-gray;
  padding: 2rem 0 2rem;

  a{
    // //color:$white;
    // transition:color .3s ease;
    // &:hover{
    //     color:$black;
    // }
  }
  .menu{
    margin:0;
    li,a{
      margin-left:0;
    //  padding-left:0;
      font-size:.85rem;
    }
    li{
      border-right:1px solid $medium-gray;
      &:last-child{
        border:0;
      }
    }
  }
  h5{
    font-size:.85rem;
    border-bottom:1px dotted $medium-gray;
    margin-bottom:.75rem;
  }
}

#stage{
  position:relative;
  height:450px;
  @include breakpoint(medium) {
    height:600px;
  }
  overflow:hidden;
  background-position: center center;
  background-size: cover;
  > img{
    transition: all ease .4s;
    width:100%;
  }
  &.focus{
    > img{
      filter: blur(150px);
    }
  }

  #searchbox{
    position:absolute;
    width:100%;
    @include vertical-center;

    label{
      color:$white;
      @include breakpoint(medium) {
        margin-bottom:1rem;
      }
    }
    .button.hollow{
      border-color:$white;
      color:$white;
    }

    .callout{
      label{
        color:$black;
      }
      .button.hollow{
        border-color:$secondary-color;
        color:$secondary-color;
      }
    }
  }
}


.tabs{

  .tabs-title{
    a{
      transition:all .3s ease;
      position:relative;
      box-shadow: -1px 0 0 #fff;
      .fa {
        display: block;
        text-align: center;
        margin-bottom: .35rem;
      }

      &::after {
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -10px;
        width: 0;
        height: 0;
        border: solid transparent;
        border-top-width: medium;
        border-right-width: medium;
        border-bottom-width: medium;
        border-left-width: medium;
        border-top-color: transparent;
        border-width: 10px;
        border-top-color: $dark-gray;
        content: '';
        pointer-events: none;
        display:none;
          transition:all .3s ease;
      }
    }
    &.is-active{
      a{
        &::after{
          display:block;
        }
      }
    }
    &:hover{
      background:$primary-color;
      a{
        background:$primary-color;
        color:$white;
        &::after{
          display:block;
          border-top-color:$primary-color;
        }
      }
    }
  }
  .tabs-content{
    padding-top:2rem;
  }
}

.reveal{
  padding-top:rem-calc(50);
  h1{font-size:1.65rem;font-weight:bolder;}
  .close-button{
    color:$white;
    padding: 0 .5rem .25rem .5rem;
  }
  h2{
    font-size:1.45rem;
  }
  h3{
    font-size:1.25rem;
  }

  &#terms{

  }
}

#cookie-legal-notice{
  position: fixed;
  bottom: 50px;
  left:10px;
  box-shadow:0 0 10px $dark-gray;
width: 350px;
  background: $ms-gradient;//rgba($white,.85);
  padding: 1rem;
  font-size:90%;
  color:$white;
  border-radius: $global-radius;
  button{
    margin-top:.5rem;
    color:$white;
    border-color:$white;
  }
}

ul.ms-features{
  list-style:none;
  margin:0;
  padding:0;

  li{
    &::before{
      content:'\f00c';
      font-family:'FontAwesome';
      color:$primary-color;
      margin-right: .5rem;
    }
    border-bottom: 1px dotted $light-gray;
    margin-bottom:.5rem;padding-bottom:.5rem;
    &:last-child(){
      border:0;
    }
  }

}

.youtube-privacy{
  font-size:75%;
  color:$medium-gray;
}
