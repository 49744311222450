.tabs-content,.tabs{
  border:0;
}

.callout.results{
  background:none;
  color:$dark-gray;
  border-color:$medium-gray;
  @include breakpoint(small only) {
    font-size:80%;
  }
}

#craftsmen-listing {
  margin:0;
  border:0;


  tbody,tr{
    border:0;
  }
  .ad{

    border-bottom:3px solid $light-gray;
    .ad{
      text-align: center;
      span{
        font-size:60%;
        display:block;
        text-align: left;
      }
    }
  }
  .craftsman{
    border-bottom:3px solid $light-gray;
    margin-bottom:1rem;
    padding-bottom:1rem;
    &:last-child{
      border:0;
    }
    td{
      @include breakpoint(small only) {

      border-top: 0;
      padding-bottom: 0;
      padding-top: .15rem;
      }


      &.logo{

        width:rem-calc(100px);
        @include breakpoint(medium up) {
          width:rem-calc(100px);
        }

        img{
          width:100%;
          border-radius:50%;
          border:1px solid $medium-gray;
          transition:all 1s ease-in-out;
          &:hover{
            border-radius: 0;
          }
        }
      }
      &.location{
        .location{
          @include breakpoint(medium up) {
            font-size:80%;
          }
          color:$dark-gray;
        }
      }

    }

    .profile{
      &.name{
          &.name-1{
            font-weight:bolder;
            font-family: $header-font-family;
            font-size:1.25rem!important;
            border-bottom:1px solid $primary-color;
            transition:all .4s ease;
            @include breakpoint(medium up) {
              font-size:80%;
            }
            &:hover{
              border-color:$medium-gray;
            }
          }
          &.name-2{
            @include breakpoint(medium up) {
              font-size:80%;
            }
          }
      }
    }
  }

}
