.craftsman-profile-container{

  background:$white;
  box-shadow:0 0 5px rgba($dark-gray,.5);
  padding:0;

  margin:0;
  @include breakpoint(medium up) {
    margin:5rem 0;
  }

  padding-bottom:3rem;

  &.classic{
      box-shadow: none;
      background:none;
  }

  .title{
    h4{
      font-family:$body-font-family;
      text-transform: uppercase;
      color:$medium-gray;
      margin-top:-15px;
    }
  }

  .own-profile{
    margin-top: 2rem;
  }


  .baseData{
    .grid-padding-x{
      .cell:first-child{
        padding-left:0;
      }
    }
  }

  #teaser{
    position:relative;
    min-height:300px;
    .logo-wrap{
      position:absolute;
      @include breakpoint(small only) {
        width: 180px;
        bottom: 0;
        left: 10px;

      }

      border:1px solid $medium-gray;
      background:$white;
      padding:.75rem;
      box-shadow:0 0 5px rgba($dark-gray,.5);
      @include breakpoint(medium up) {

        top:10px;
        left:auto;
        bottom:auto;
        right:10px;
        max-height: 200px;
        overflow: hidden;

      }
    }
  }

  .profile-teaser-wrap{
    .profile-teaser-image{
      width:100%;
    }
  }

  .video-container{
    .callout{
      border-radius:0;
      border:0;
      height:315px;
      position:relative;
      background: repeating-linear-gradient(
        45deg,
        $white,
        $white 10px,
        rgba($light-gray,.5) 10px,
        rgba($light-gray,.5) 20px
      );
      .inner{
        @include vertical-center;
        text-align: center;
        width: 95%;
        .description{font-size:80%;}
        // background:rgba($white,.5);
        // padding:1rem;
      }
    }
  }

  // .embed-responsive-item{
  //   width:100%;
  // }
  .taglist{
      margin:1rem 0;
    li{
      border-radius: $global-radius;
      padding:.15rem .35rem;
      background:$ms-gradient;
      color:$white;
      margin-right:.5rem;
      font-size:80%;
      float: left;
      margin-bottom: .5rem;
    }
  }

  #sidebar{
    .widget{
      font-size:80%;
      margin-bottom:1.75rem;
      table{
        font-size: inherit;
      }
      h4{
        font-size:1.25rem;
      }

    }
  }

}


form.activation{
  fieldset{
    background: $white;
    padding: .5rem 1rem;
    margin-bottom: 1rem;
    border-radius: $global-radius;
    label{
      color:$black;
    }
  }
}
