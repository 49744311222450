#tm-tags-container{
  margin-bottom:2rem;
  .tm-tag {
    padding: .35rem;
    background: $light-gray;
    border-radius: 10px;
    margin-right: .25rem;
    font-size: 80%;
    box-shadow:0 0 5px rgba($medium-gray,.5);
    transition: all .3s ease;
    display: inline-block;
    margin-bottom: .25rem;

    .tm-tag-remove {
      padding: 0 .35rem 0;
      border-left: 1px solid $medium-gray;
      margin-left: .35rem;
    }

    &:hover{
      background:$primary-color;
      color:$white;

      .tm-tag-remove{
        border-color:$white;
        color:$white;
        text-decoration: none;
      }
    }

  }



}
